import { createSelector } from 'redux-bundler';

import FsxFileSystem from '../models/fsx_file_system';

const STALE_AFTER = 3000000;

export default {
  name: 'fsx_file_systems',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      scope: 'client',
      fetchLastSuccess: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_FSX_FILE_SYSTEMS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_FSX_FILE_SYSTEMS_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.results,
          scope: payload.scope,
          fetchLastSuccess: payload.time,
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      return state;
    };
  },

  doFetchFsxFileSystems: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { sortBy } = state.fsx_file_systems;
    const { sortDirection } = state.fsx_file_systems;

    const client = store.selectActiveClient(state);
    const facility = store.selectActiveFacility(state);

    dispatch({ type: 'FETCH_FSX_FILE_SYSTEMS_START' });
    const response = await FsxFileSystem.where({ facility_id: facility.id })
      .order({ createdAt: 'desc' })
      .all();
    dispatch({
      type: 'FETCH_FSX_FILE_SYSTEMS_SUCCESS',
      payload: { results: response.data, scope: 'client', time: Date.now() },
    });
  },

  selectFsxFileSystemLoading: state => state.fsx_file_systems.loading,
  selectFsxFileSystemRaw: state => state.fsx_file_systems,
  selectFsxFileSystemData: state => state.fsx_file_systems.data || [],

  selectFsxFileSystemFetchLastSuccess: createSelector(
    'selectFsxFileSystemRaw',
    fsxFileSystemData => fsxFileSystemData.fetchLastSuccess,
  ),
  selectFsxFileSystemsAreStale: createSelector(
    'selectFsxFileSystemFetchLastSuccess',
    'selectAppTime',
    (lastSuccessTime, appTime) => {
      if (!lastSuccessTime) {
        return true;
      }
      return appTime - lastSuccessTime > STALE_AFTER;
    },
  ),
  selectSelectedFsxFileSystemCount: createSelector(
    'selectFsxFileSystemData',
    teamData => teamData.filter(u => u.selected).length,
  ),
  selectSelectedFsxFileSystems: createSelector(
    'selectFsxFileSystemData',
    teamData => teamData.filter(u => u.selected),
  ),

  reactShouldFetchFsxFileSystems: createSelector(
    'selectRouteApis',
    'selectFsxFileSystemRaw',
    'selectCurrentUser',
    'selectActiveClient',
    'selectActiveFacility',
    'selectFsxFileSystemsAreStale',
    (
      apis,
      fsxFileSystemData,
      currentUser,
      activeClient,
      activeFacility,
      isStale,
    ) => {
      const wantsFsxFileSystems = apis.includes('fsxFileSystems');

      if (
        !wantsFsxFileSystems ||
        fsxFileSystemData.loading ||
        !currentUser ||
        !activeClient ||
        !activeFacility
      ) {
        return false;
      }

      if (fsxFileSystemData.data && !isStale) {
        return false;
      }
      return { actionCreator: 'doFetchFsxFileSystems' };
    },
  ),

  // reactShouldFetchFsxFileSystemsForFacilities: createSelector(
  //   'selectRouteApis',
  //   'selectFsxFileSystemRaw',
  //   'selectCurrentUser',
  //   'selectActiveFacility',
  //   (apis, fsxFileSystemData, currentUser, activeFacility) => {
  //     const wantsFsxFileSystems = apis.includes('fsxFileSystems_for_facility');

  //     if (
  //       !wantsFsxFileSystems ||
  //       fsxFileSystemData.loading ||
  //       fsxFileSystemData.data ||
  //       !currentUser ||
  //       !activeFacility
  //     ) {
  //       return false;
  //     }
  //     return { actionCreator: 'doFetchFsxFileSystemsForFacility' };
  //   },
  // ),

  // reactShouldFetchActiveFsxFileSystem: createSelector(
  //   'selectRouteParams',
  //   'selectPathname',
  //   'selectActiveFsxFileSystem',
  //   'selectFsxFileSystemRaw',
  //   (routeParams, pathname, activeFsxFileSystem, teamData) => {
  //     if (
  //       !pathname.includes('/fsx_file_systems') ||
  //       !routeParams.fsxFileSystemId ||
  //       teamData.loadingActiveFsxFileSystem
  //     ) {
  //       return null;
  //     }
  //     if (
  //       activeFsxFileSystem &&
  //       activeFileUpload.id === routeParams.fileUploadId
  //     ) {
  //       return null;
  //     }

  //     return {
  //       actionCreator: 'doFetchActiveFileUpload',
  //       args: [routeParams.fileUploadId],
  //     };
  //   },
  // ),

  // reactScopeChange: createSelector(
  //   'selectRouteApis',
  //   'selectFileUploadRaw',
  //   (apis, fileUploadData) => {
  //     let scope;
  //     if (apis.includes('fileUploads')) scope = 'client';

  //     if (apis.includes('fileUploads_for_facility')) scope = 'facility';
  //     if (fileUploadData.scope && fileUploadData.scope !== scope) {
  //       return { actionCreator: 'doResetFileUploads' };
  //     }

  //     return null;
  //   },
  // ),

  // reactShouldResetActiveFileUpload: createSelector(
  //   'selectActiveFileUploadId',
  //   'selectActiveFileUpload',
  //   (activeFileUploadId, activeFileUpload) => {
  //     if (!activeFileUpload) return null;
  //     if (activeFileUpload.id !== activeFileUploadId) {
  //       return { actionCreator: 'doResetActiveFileUpload' };
  //     }

  //     return null;
  //   },
  // ),
};
