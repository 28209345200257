import { Avatar, Badge, Divider, Menu, MenuItem } from '@mui/material';
import { connect } from 'redux-bundler-react';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

import AuthorizationCheck from '../../atoms/AuthorizationCheck';
import NestedMenuItem from '../../atoms/NestedMenuItem';
import PasswordChangeDialog from '../../pages/password_change_dialog';
import SearchReindexation from '../../../models/search_reindexation';
import SplitTestDialog from '../../pages/split_test_dialog';
import styles from './styles';

const StyledBadge = withStyles(Badge, {
  badge: {
    backgroundColor: 'rgb(220, 0, 78)',
    color: 'rgb(220, 0, 78)',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
});

const UserAvatar = withStyles(
  ({ authenticatedUser, user, onClick, classes, dataTest }) => (
    <Avatar
      src={
        authenticatedUser
          ? authenticatedUser.avatarImageUrl
          : user?.attributes?.picture
      }
      className={classes.avatar}
      onClick={onClick}
      dataTest={dataTest}
    >
      {authenticatedUser && authenticatedUser.initials()}
    </Avatar>
  ),
  styles,
);

class UserMenu extends Component {
  constructor() {
    super();

    this.state = {
      anchorEl: null,
      passwordChangeDialogOpen: false,
      splitTestDialogOpen: false,
    };
  }

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleThemeMenuItemClick = theme => {
    const { doSetThemeSetting } = this.props;
    doSetThemeSetting(theme);
    this.setState({ anchorEl: null });
  };

  handleDisplayModeMenuItemClick = mode => {
    const { doSetDisplayMode } = this.props;
    doSetDisplayMode(mode);
    this.setState({ anchorEl: null });
  };

  showPasswordChangeDialog = () => {
    this.setState({ anchorEl: null, passwordChangeDialogOpen: true });
  };

  showSplitTestDialog = () => {
    this.setState({ anchorEl: null, splitTestDialogOpen: true });
  };

  hideSplitTestDialog = () => {
    this.setState({ splitTestDialogOpen: false });
  };

  hidePasswordChangeDialog = () => {
    this.setState({ passwordChangeDialogOpen: false });
  };

  toggleDisableAutoLogout = () => {
    const { doSetDisableAutoLogout, disableAutoLogout } = this.props;

    doSetDisableAutoLogout(!disableAutoLogout);
  };

  togglePrivacyMode = () => {
    const { doSetPrivacyMode, privacyMode } = this.props;

    doSetPrivacyMode(!privacyMode);
  };

  handleSearchReindexation = () => {
    const searchReindexation = new SearchReindexation();
    searchReindexation.save();
  };

  render() {
    const {
      authenticatedUser,
      user,
      doDestroyMasquerade,
      doSetThemeSetting,
      classes,
      doChangePassword,
      activeClient,
      privacyMode,
      doLogout,
      disableAutoLogout,
    } = this.props;

    const {
      anchorEl,
      passwordChangeDialogOpen,
      splitTestDialogOpen,
    } = this.state;
    const open = Boolean(anchorEl);
    const isMasquerading = Boolean(
      authenticatedUser && authenticatedUser.masqueradeId,
    );

    return user ? (
      <>
        {isMasquerading ? (
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <UserAvatar
              dataTest="user-menu-avatar"
              authenticatedUser={authenticatedUser}
              user={user}
              onClick={this.handleMenuClick}
              className={classes.icon}
            />
          </StyledBadge>
        ) : (
          <UserAvatar
            data-test="user-menu-avatar"
            authenticatedUser={authenticatedUser}
            user={user}
            onClick={this.handleMenuClick}
          />
        )}
        {activeClient && (
          <PasswordChangeDialog
            open={passwordChangeDialogOpen}
            onClose={this.hidePasswordChangeDialog}
          />
        )}

        <SplitTestDialog
          open={splitTestDialogOpen}
          onClose={this.hideSplitTestDialog}
        />

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              maxHeight: 48 * 6.5,
            },
          }}
          getContentAnchorEl={null}
        >
          {authenticatedUser && authenticatedUser.masqueradeId && (
            <MenuItem onClick={doDestroyMasquerade}>Stop Masquerading</MenuItem>
          )}

          {activeClient && !activeClient.isScimProvisioned() && (
            <AuthorizationCheck permissionName="ui.password.change.view">
              <MenuItem onClick={this.showPasswordChangeDialog}>
                Change Password
              </MenuItem>
            </AuthorizationCheck>
          )}

          <AuthorizationCheck permissionName="ui.demo.privacy">
            <MenuItem onClick={this.togglePrivacyMode}>
              {privacyMode ? 'Disable Privacy Mode' : 'Enable Privacy Mode'}
            </MenuItem>

            <MenuItem onClick={this.toggleDisableAutoLogout}>
              {disableAutoLogout ? 'Enable Auto Logout' : 'Disable Auto Logout'}
            </MenuItem>
          </AuthorizationCheck>

          <AuthorizationCheck permissionName="ui.split.test">
            <MenuItem onClick={this.showSplitTestDialog}>Split Test</MenuItem>
          </AuthorizationCheck>

          <AuthorizationCheck permissionName="search.reindex">
            <MenuItem onClick={this.handleSearchReindexation}>
              Perform Search Reindexation
            </MenuItem>
          </AuthorizationCheck>
          <AuthorizationCheck permissionName="ui.theme.view">
            <NestedMenuItem label="Theme" mainMenuOpen={open}>
              <MenuItem onClick={() => this.handleThemeMenuItemClick('light')}>
                Light
              </MenuItem>
              <MenuItem onClick={() => this.handleThemeMenuItemClick('dark')}>
                Dark
              </MenuItem>
              <MenuItem onClick={() => this.handleThemeMenuItemClick('system')}>
                System
              </MenuItem>
            </NestedMenuItem>
            <NestedMenuItem label="Display Mode" mainMenuOpen={open}>
              <MenuItem
                onClick={() => this.handleDisplayModeMenuItemClick('normal')}
              >
                Normal
              </MenuItem>
              <MenuItem
                onClick={() => this.handleDisplayModeMenuItemClick('wide')}
              >
                Widescreen
              </MenuItem>
            </NestedMenuItem>
            <Divider />
          </AuthorizationCheck>
          <MenuItem onClick={() => doLogout()}>Log Out</MenuItem>
        </Menu>
      </>
    ) : null;
  }
}

/* UserMenu.propTypes = {
  user: PropTypes.instanceOf(CognitoUser),
  authenticatedUser: PropTypes.instanceOf(User),
  doDestroyMasquerade: PropTypes.func.isRequired,
  doSetThemeSetting: PropTypes.func.isRequired,
}; */

UserMenu.defaultProps = {
  authenticatedUser: null,
  user: null,
};

export default connect(
  'doSetThemeSetting',
  'selectAuthenticatedUser',
  'doDestroyMasquerade',
  'doChangePassword',
  'doLogout',
  'selectActiveClient',
  'selectPrivacyMode',
  'doSetPrivacyMode',
  'doSetDisplayMode',
  'doSetDisableAutoLogout',
  'selectDisableAutoLogout',
  withStyles(UserMenu, styles),
);
