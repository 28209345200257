import { Model, Attr, HasMany, BelongsTo, HasOne } from 'spraypaint';

import { flatten, sumBy, sortBy, reverse } from 'lodash';
import { ApplicationRecord } from './application_record';
import Client from './client';
import CrossAccountRoleInstallation from './cross_account_role_installation';
import Region from './region';

@Model()
export default class Account extends ApplicationRecord {
  static jsonapiType = 'accounts';
  @Attr() name: string;
  @Attr() accountId: string;
  @Attr() email: string;
  @Attr() type: string;
  @Attr() roleArn: string;
  @Attr() kmsKeyId: string;
  @Attr() enableLeastPrivilegeIam: boolean;
  @Attr() enableLambdaPermissionBoundary: boolean;
  @Attr({ persist: false }) selected: boolean;
  @Attr({ persist: false }) clientId: string;
  @Attr({ persist: false }) clientName: boolean;
  @Attr({ persist: false }) clientDeleted: boolean;
  @Attr() partOfOrganisation: boolean;
  @Attr() costError: any;
  @Attr() costs: any;
  @Attr() costLastMonth: number;
  @Attr() costMtd: number;
  @Attr() mapLastMonth: number;
  @Attr() mapMtd: number;
  @Attr() mapCoverageMtd: number;
  @Attr() mapCoverageLastMonth: number;

  @BelongsTo() client: Client;
  @HasMany() regions: Region[];
  @HasOne() crossAccountRoleInstallation: CrossAccountRoleInstallation;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }

  previousMonthCost(): string {
    return `$${(this.costLastMonth || 0).toFixed(2)}`;
  }

  previousMonthMap(): string {
    return `$${(this.mapLastMonth || 0).toFixed(2)}`;
  }

  mtdMap(): string {
    return `$${(this.mapMtd || 0).toFixed(2)}`;
  }

  mtdCost(): string {
    return `$${(this.costMtd || 0).toFixed(2)}`;
  }

  mtdMapCoverage(): string {
    return `${this.mapCoverageMtd || 0}%`;
  }

  previousMonthMapCoverage(): string {
    return `${this.mapCoverageLastMonth || 0}%`;
  }

  serviceCostBreakdown(): any[] {
    if (!this.costs) return [];

    if (!this.costs.by_service?.results_by_time) return [];
    const results = this.costs.by_service.results_by_time || [];
    const month = results[0] || {};
    const groups = (month['groups'] || []).map((g: any) => ({
      name: g['keys'],
      cost: parseFloat(g['metrics']['UnblendedCost']['amount']),
    }));
    return reverse(sortBy(groups, g => g.cost)).filter(
      g => Math.abs(g.cost) > 0.01,
    );
  }

  regionCostBreakdown(): any[] {
    if (!this.costs) return [];

    if (!this.costs.by_region?.results_by_time) return [];
    const results = this.costs.by_region.results_by_time || [];
    const month = results[0] || {};
    const groups = (month['groups'] || []).map((g: any) => ({
      name: g['keys'],
      cost: parseFloat(g['metrics']['UnblendedCost']['amount']),
    }));
    return reverse(sortBy(groups, g => g.cost)).filter(
      g => Math.abs(g.cost) > 0.01,
    );
  }

  dailyCostBreakdown(service: string): any[] {
    console.log('filtering service', service);
    if (!this.costs) return [];

    if (!this.costs.by_daily?.results_by_time) return [];
    const results = this.costs.by_daily.results_by_time || [];
    return results.map((resultForDay: any) => {
      return [
        Date.parse(resultForDay.time_period.start),
        sumBy(resultForDay['groups'], (g: any) => {
          if (service) {
            return g.keys.includes(service)
              ? parseFloat(g.metrics.UnblendedCost.amount)
              : 0;
          } else {
            return parseFloat(g.metrics.UnblendedCost.amount);
          }
        }),
      ];
    });
  }

  dailyCostBreakdownBySeries(): any[] {
    if (!this.costs) return [];

    if (!this.costs.by_daily?.results_by_time) return [];
    const results = this.costs.by_daily.results_by_time || [];
    const data = flatten(
      results.map((resultForDay: any) =>
        resultForDay['groups'].map((g: any) => ({
          x: Date.parse(resultForDay.time_period.start),
          y: parseFloat(g.metrics.UnblendedCost.amount),
          series: g.keys[0],
        })),
      ),
    );
    return data;
  }
}
